import Cookie from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum'

export const isProductionNodeEnv = () => process.env.NODE_ENV === 'production';
export const isStagingNodeEnv = () => process.env.NODE_ENV === 'staging';
export const isQaNodeEnv = () => process.env.NODE_ENV === 'qa';
export const isDevelopmentNodeEnv = () => process.env.NODE_ENV === 'development';

/**
 * Wraps a possible undefined into a null value
 * @param {(*|null|undefined)} maybeUndef a value that could be null, undefined or object
 * @returns {(null|{object})} The original object if exists, null otherwise
 */
export function undefinedHandler(maybeUndef) {
  if (typeof maybeUndef === 'undefined' || maybeUndef === null) {
    return null;
  }
  return maybeUndef;
}

/**
 * Checks if is possible to push on Google Analytics
 * @return {bool} a flag that says if we can push on GA
 */
export function canUseAnalytics() {
  return undefinedHandler(window.ga) && isNeededCookieEnabled();
}

/**
 * @returns {boolean} that says if user has accepted first part cookies (obliged)
 */
export function isNeededCookieEnabled() {
  return Cookie.get('cookies_acceptance') ? Cookie.get('cookies_acceptance').includes('needed') : false;
}
